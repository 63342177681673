import gql from 'graphql-tag';

export const HelpPanelQuery = gql`
	query HelpPanelQuery {
		user(current: true) {
			id
			displayName
			emails {
				value
				isPrimary
			}
		}
	}
`;

import { LoadableAfterPaint } from '@confluence/loadable';

export const FeedbackCollector = LoadableAfterPaint<any>({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-FeedbackCollectorDialog" */ './FeedbackCollectorDialog'
			)
		).FeedbackCollector,
});

import { LoadableAfterPaint } from '@confluence/loadable';

export const KeyboardShortcutsDialog = LoadableAfterPaint<any>({
	loader: async () => {
		return (
			await import(
				/* webpackChunkName: "loadable-KeyboardShortcutsDialog" */ './KeyboardShortcutsDialog'
			)
		).KeyboardShortcutsDialog;
	},
});

export { KeyboardShortcutsDialogQuery } from './KeyboardShortcutsDialogQuery.experimentalgraphql';
